<template>
  <div class="team p20 bsbb">
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold; font-size: 20px; line-height: normal">團隊</span>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <div class="padded-15">
      <div class="box">
        <div class="box-t">
          <div class="title">
            <div>總業績</div>
            <div class="text-warning font-weight">
              {{ Number(totalYongJinAll).toFixed(2) }}
            </div>
          </div>
        </div>
        <!-- <div class="box-b">
          <div class="padded-b-15">
            <div class="font-size-18 font-weight">
              ￥{{ Number(yongJin1).toFixed(2) }}
            </div>
            <div class="padded-t-5">一级佣金</div>
          </div>
          <div class="padded-b-15">
            <div class="font-size-18 font-weight">
              ￥{{ Number(yongJin2).toFixed(2) }}
            </div>
            <div class="padded-t-5">二级佣金</div>
          </div>
          <div class="padded-b-15">
            <div class="font-size-18 font-weight">
              ￥{{ Number(yongJin3).toFixed(2) }}
            </div>
            <div class="padded-t-5">三级佣金</div>
          </div>
        </div> -->
      </div>
      <!-- <div class="box">
        <div class="box-t">
          <div class="title">
            <div>贡献值</div>
            <div class="text-warning font-weight">
              {{ userInfo.contribute_score }}
            </div>
          </div>
        </div>
      </div> -->

      <div class="box">
        <div class="box-t">
          <div class="title">
            <div>總人數</div>
            <div class="text-warning font-weight">
              {{ totalCountAll }}<span class="font-size-14">人</span>
            </div>
          </div>
        </div>
        <div class="box-b">
          <div class="padded-b-15">
            <div class="font-size-38 font-weight">
              {{ level1Count }}<span class="font-size-38 text-grey">人</span>
            </div>
            <div class="padded-t-5 text-grey" style="font-size: 0.5rem;">直推人數</div>
          </div>
          <!-- <div class="padded-b-15">
            <div class="font-size-18 font-weight">
              {{ level2Count }}<span class="font-size-14 text-grey">人</span>
            </div>
            <div class="padded-t-5 text-grey">二级人数</div>
          </div>
          <div class="padded-b-15">
            <div class="font-size-18 font-weight">
              {{ level3Count }}<span class="font-size-14 text-grey">人</span>
            </div>
            <div class="padded-t-5 text-grey">三级人数</div>
          </div> -->
        </div>
      </div>
      <div class="df aic jcsb p15 bsbb fz40">
        <p>錢包地址</p>
        <p>團隊人數</p>
      </div>
      <div v-for="item in data" :key="item.id" class="p20 bsbb">
        <div style="display: flex; margin-top: 10px" class="df aic jcsb fz36">
          <span class="money">{{ (item.dapp_address.substring(0,3)+ "****" + item.dapp_address.substr(item
          .dapp_address.length - 4)) }}</span>
          <span class="time" style="text-align: right; width: 100px">{{
            item.num
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMyTeam, getZhitui, getUserInfo } from "@/utils/api.js";
export default {
  name: "team",
  data() {
    return {
       userInfo: {},
      list: [],
      data: [],
      level1Count: 0,
      level2Count: 0,
      level3Count: 0,
      totalCount: 0,
      yongJin1: "0.00",
      yongJin2: "0.00",
      yongJin3: "0.00",
      totalYongJin: "0.00",
      totalCountAll: 0,
      totalYongJinAll: 0,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {

      getUserInfo().then((res) => {
        this.userInfo = res.data.user;

      });
      getMyTeam().then((res) => {
        this.list = res.data;
        this.level1Count = res.data.level1Count;
        this.level2Count = res.data.level2Count;
        this.level3Count = res.data.level3Count;
        this.totalCountAll = res.data.totalCountAll;
        this.totalYongJinAll = res.data.totalYongJinAll;
        this.totalCount =
          this.level1Count + this.level2Count + this.level3Count;
        this.yongJin1 = res.data.yongJin1;
        this.yongJin2 = res.data.yongJin2;
        this.yongJin3 = res.data.yongJin3;
        this.totalYongJin = this.yongJin1 + this.yongJin2 + this.yongJin3;
      });
      getZhitui().then((res) => {
        this.data = res.data;
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-icon {
  font-size: 32px;
}

::v-deep .van-nav-bar__arrow {
  font-size: 46px !important;
  color: #000;
}

::v-deep .van-nav-bar__content {
  height: 70px;
  line-height: 70px;
  box-shadow: none !important;
}

.van-dropdown-menu__bar {
  box-shadow: none !important;
}

.team {
  background: linear-gradient(180deg,
      rgba(55, 158, 0, 0.1) 0%,
      rgba(55, 159, 0, 0) 100%);
  height: 100%;

  .box {
    background: hsla(0, 0%, 100%, 0.7);
    margin-bottom: 15px;
    border-radius: 8px;

    .box-t {
      padding: 15px;
      margin: 0 15px;
      align-items: center;
      border-bottom: 1px solid #e3e3e3;
    }

    .title {
      font-size: 50px;
      color: #656464;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }

    .box-b {
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      margin-top: 15px;
      font-size: 12px;
    }
  }
}
</style>
